"use client";

import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Image from "next/image";
import { type MutableRefObject, useEffect, useRef, useState } from "react";

import { Button, Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getImageDimensions,
  getLinkProps,
  getPosterUrl,
} from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";

import { RichText } from "../rich-text";
import type { ShowcaseSectionBlok } from "./types";

type ShowcaseSectionProps = {
  blok: ShowcaseSectionBlok;
} & PageContext;

export function ShowcaseSection({ blok, ...context }: ShowcaseSectionProps) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const trackRef = useRef<HTMLTrackElement | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const { isMobile } = useScreenSize();

  // Create captions file, and set it on video
  useEffect(() => {
    if (!blok.captions || !trackRef.current) return;

    const blob = new Blob([blok.captions], { type: "text/vtt" });
    const url = URL.createObjectURL(blob);
    trackRef.current.src = url;
  }, [blok.captions]);

  // Enables picture in picture when the user scrolls away from a playing section.
  usePictureInPicture({ videoRef, isAutoplay: blok.autoplay === true });

  return (
    <section
      id={blok.sectionId}
      data-section-type="showcase"
      className={cn(
        "grid place-items-center bg-surface/200 lg:grid-cols-[1fr_1fr]",
        blok.theme,
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "accent" && "bg-surface/accent",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
        blok.backgroundVariant === "white" && "bg-others-white",
      )}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          "relative flex h-full w-full flex-col place-content-center place-items-center p-sm-md py-2xl lg:p-xl md:p-lg-xl",
          // 1 pixel left overlap, bc. the pattern is creating a bare "line" on some screens.
          "lg:-ml-[1px] lg:col-start-2 max-lg:pt-3xl-4xl",
        )}
      >
        <div
          className={cn("absolute top-[0] z-0 h-full w-full")}
          style={{
            backgroundColor: blok.mediaBackgroundColor ?? undefined,
          }}
          data-chromatic="ignore"
        >
          {blok.mediaBackground?.filename && (
            <Image
              sizes="100vw"
              src={blok.mediaBackground.filename}
              {...getImageDimensions(blok.mediaBackground)}
              alt={blok.mediaBackground.alt}
              loader={buildImageLoader({ focus: blok.mediaBackground.focus })}
              className="h-full object-cover"
            />
          )}
        </div>

        <div
          className={cn(
            "absolute top-[0] z-0 h-full w-full bg-surface/200 [mask-size:65%]",
            "[mask-image:url(/masks/seed-corner-2.svg)] [mask-position:top_left]",
            blok.backgroundVariant === "darker" && "bg-surface/300",
            blok.backgroundVariant === "accent" && "bg-surface/accent",
            blok.backgroundVariant === "lighter" && "bg-surface/100",
            blok.backgroundVariant === "white" && "bg-others-white",
          )}
        />

        {blok.video?.filename && (
          <div className="lg:-translate-y-1/2 relative z-10 flex content-center items-center lg:sticky lg:top-1/2">
            <video
              ref={videoRef}
              controls
              poster={getPosterUrl({
                image: blok.image,
                width: isMobile ? 600 : 1280,
              })}
              muted={blok.autoplay === true}
              loop={blok.autoplay === true}
              autoPlay={blok.autoplay === true}
              // Use a limited set of controls, as the video is essentially "gif-like" at this point.
              controlsList={
                blok.autoplay === true
                  ? "nofullscreen nodownload noplaybackrate"
                  : undefined
              }
              disablePictureInPicture={blok.autoplay === true}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              className="h-full w-full rounded-md"
              data-chromatic="ignore"
            >
              <source src={blok.video?.filename ?? ""} />

              {blok.captions && (
                <track
                  ref={trackRef}
                  kind="captions"
                  srcLang={context.locale}
                  default
                />
              )}
            </video>

            {/* We only show the play icon on desktop, as mobile has its own UI handling this. */}
            {!isMobile && !isPlaying && (
              <Icon
                name="Play"
                weight="duotone"
                className="circle-icon -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 text-others-white"
                onClick={() => videoRef.current?.play()}
                aria-hidden
              />
            )}
          </div>
        )}

        {!blok.video?.filename && blok.image?.filename && (
          <Image
            sizes="(max-width: 1024px) 100vw, 50vw"
            src={blok.image.filename}
            {...getImageDimensions(blok.image)}
            alt={blok.image.alt}
            loader={buildImageLoader({ focus: blok.image.focus })}
            className="lg:-translate-y-1/2 z-10 lg:sticky lg:top-1/2"
            data-chromatic="ignore"
          />
        )}
      </div>

      <div className="grid w-full grid-cols-[minmax(auto,24em)] place-content-center gap-xl px-sm-md py-3xl-4xl lg:row-start-1 lg:px-lg md:px-lg-xl max-lg:pt-2xl-3xl">
        <div className="grid gap-sm">
          {blok.leading && (
            <span className="t-prosi-xl -mb-2xs">{blok.leading}</span>
          )}

          {blok.title && <h2 className="t-strong-3xl">{blok.title}</h2>}

          <RichText data={blok.text} className="t-strong-lg" {...context} />
        </div>

        <ul>
          {blok.items.map(item => (
            <li
              key={item._uid}
              className="flex place-items-center gap-sm border-border/200 border-b p-sm"
              {...storyblokEditable(item as SbBlokData)}
            >
              <Icon name={item.icon} weight="duotone" className="circle-icon" />
              <RichText data={item.text} className="t-strong-md" {...context} />
            </li>
          ))}
        </ul>

        {blok.ctaLink && blok.ctaText && (
          <Button
            {...getLinkProps(blok.ctaLink, context)}
            className="place-self-start"
            arrow
          >
            {blok.ctaText}
          </Button>
        )}
      </div>
    </section>
  );
}

type UsePictureInPictureProps = {
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  isAutoplay: boolean;
};

/**
 * Hook to add picture in picture when the user scrolls below a playing video.
 */
function usePictureInPicture({
  videoRef,
  isAutoplay,
}: UsePictureInPictureProps) {
  const timeline = useRef<gsap.core.Timeline | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: ref is stable
  useEffect(() => {
    if (!videoRef.current || isAutoplay) {
      return () => {
        timeline.current?.kill();
      };
    }

    gsap.registerPlugin(ScrollTrigger);

    timeline.current = gsap.timeline({
      scrollTrigger: {
        trigger: videoRef.current,
        start: "bottom top", // Trigger when the element leaves the viewport (bottom hits top of viewport)
        onLeave: async () => {
          if (!videoRef.current) return;

          if (videoRef.current.paused === false) {
            try {
              await videoRef.current.requestPictureInPicture();
            } catch (err) {}
          }
        },
        onLeaveBack: () => {
          if (document.pictureInPictureElement) {
            document.exitPictureInPicture();
          }
        },
      },
    });

    return () => {
      timeline.current?.kill();
    };
  }, [isAutoplay]);
}
