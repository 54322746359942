import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { Button, Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { GridSectionBlok, GridSectionItemBlok } from "./types";

type GridSectionProps = {
  blok: GridSectionBlok;
} & PageContext;

export function GridSection({ blok, ...context }: GridSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="grid"
      className={cn(blok.theme, "section bg-surface/contrast")}
      {...storyblokEditable(blok)}
    >
      <h1 className="t-strong-4xl-5xl leading-tight">{blok.title}</h1>

      <div className="grid gap-lg">
        {blok.items.map(item => (
          <GridSectionItem key={item._uid} blok={item} {...context} />
        ))}
      </div>
    </section>
  );
}

type GridItemProps = {
  blok: GridSectionItemBlok;
} & PageContext;

// TODO: create a reusable UI "tile"
function GridSectionItem({ blok, ...context }: GridItemProps) {
  // Full width item
  if (blok.span === "2") {
    return (
      <div
        className={cn(
          blok.theme,
          "grid place-items-start p-md-lg pb-lg-xl lg:col-span-2 lg:grid-cols-2",
          "overflow-hidden rounded-md bg-surface/200 text-content-text/s lg:min-h-[24em]",
        )}
        {...storyblokEditable(blok as SbBlokData)}
      >
        <div className="grid place-items-start gap-sm">
          <Icon
            name={blok.icon}
            weight="duotone"
            className="circle-icon text-content-heading"
          />

          <span className="t-strong-md">{blok.leading}</span>

          <h2 className="-ml-[1px] t-prosi-2xl text-balance text-content-heading">
            {blok.title}
          </h2>

          <RichText
            data={blok.text}
            className="t-default-md text-pretty text-content-text"
            {...context}
          />

          {blok.ctaText && blok.ctaLink && (
            <Button
              {...getLinkProps(blok.ctaLink, context)}
              variant="ternary"
              className="-ml-md mt-auto"
            >
              {blok.ctaText}
            </Button>
          )}
        </div>

        {blok.backgroundImage?.filename && (
          <div className="-mr-lg -mt-lg -mb-[110%] -ml-[15%] md:-mb-[80%] lg:-mb-[75%] relative text-surface/300 lg:mt-lg">
            <Image
              width="400"
              height="500"
              sizes="(max-width: 768px) 100vw, (max-width: 1480px) 40%, 600px"
              src={blok.backgroundImage?.filename}
              loader={buildImageLoader({
                aspectRatio: 4 / 5,
                focus: blok.backgroundImage?.focus,
              })}
              alt={blok.backgroundImage?.alt ?? ""}
              className="h-full w-full"
              style={{
                clipPath: "url(#grid-section-seed-clip)",
              }}
              data-chromatic="ignore"
            />
            <GridItemSVGs />
          </div>
        )}
      </div>
    );
  }

  // Regular item
  return (
    <div
      className={cn(
        blok.theme,
        blok.variant === "accent" ? "bg-surface/accent" : "bg-surface/200",
        "flex flex-col place-items-start gap-sm p-md-lg pb-lg-xl",
        "rounded-sm text-content-text/s lg:min-h-[24em]",
      )}
      {...storyblokEditable(blok as SbBlokData)}
    >
      <Icon name={blok.icon} weight="duotone" className="circle-icon" />

      <span className="t-strong-md text-content-text">{blok.leading}</span>

      <h2 className="-ml-[1px] t-prosi-2xl text-balance text-content-heading">
        {blok.title}
      </h2>

      <RichText
        data={blok.text}
        className="t-default-md text-pretty text-content-text"
        {...context}
      />

      {blok.ctaText && blok.ctaLink && (
        <Button
          {...getLinkProps(blok.ctaLink, context)}
          variant="ternary"
          className="-ml-md mt-auto"
        >
          {blok.ctaText}
        </Button>
      )}
    </div>
  );
}

export const GridItemSVGs = () => (
  <>
    <svg className="h-[0]">
      <title>Mask seed</title>
      <clipPath id="grid-section-seed-clip" clipPathUnits="objectBoundingBox">
        <path d="M.1292 1A.896.896 0 0 0 .7065.6505.8854.8854 0 0 0 .8745 0 .896.896 0 0 0 .297.3495.8854.8854 0 0 0 .1291 1Z" />
      </clipPath>
    </svg>
    <svg
      className="-top-md -left-2xs pointer-events-none absolute h-full w-full"
      viewBox="0 0 1 1"
    >
      <title>Accent seed</title>
      <g fill="currentColor">
        <path d="M.2442.3232A.119.119 0 0 0 .2728.2383.1176.1176 0 0 0 .234.158a.119.119 0 0 0-.0286.0849.1176.1176 0 0 0 .0388.0803ZM.293.2951A.0742.0742 0 0 0 .34.2033.0742.0742 0 0 0 .293.295Z" />
      </g>
    </svg>
  </>
);
