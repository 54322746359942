import { storyblokEditable } from "@storyblok/react/rsc";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Image from "next/image";
import { type RefObject, useEffect, useMemo, useRef } from "react";
import { Button } from "~components/ui";
import { SeedWave } from "~components/ui/seed-wave";
import {
  type PageContext,
  buildImageLoader,
  getImageDimensions,
  getLinkProps,
  imageLoader,
} from "~lib/storyblok";
import { cn, useScreenSize } from "~utils";
import { RichText } from "../rich-text";
import type { AgreenaSectionBlok } from "./types";

type AgreenaSectionProps = {
  blok: AgreenaSectionBlok;
} & PageContext;

export function AgreenaSection({ blok, ...context }: AgreenaSectionProps) {
  const logoRef = useRef<HTMLDivElement>(null);
  const logoBgRef = useRef<HTMLImageElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);

  const headerFirstRef = useRef<HTMLHeadingElement>(null);
  const headerSecondRef = useRef<HTMLHeadingElement>(null);

  const contentRef = useRef<HTMLDivElement>(null);
  const featuredRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useScreenSize();

  const [titleFirstPart, titleSecondPart] = useMemo(() => {
    const text = isMobile ? blok.titleMobile || blok.title : blok.title;
    const [first, ...remaining] = text.split("\n");
    return [first, remaining.join("\n")];
  }, [blok, isMobile]);

  useEffect(() => {
    if (
      !sectionRef.current ||
      !logoRef.current ||
      !contentRef.current ||
      !headerFirstRef.current ||
      !headerSecondRef.current ||
      (!featuredRef.current &&
        blok.featuredLabel &&
        blok.featuredSection?.length)
    ) {
      return;
    }

    gsap.registerPlugin(ScrollTrigger);

    const elementsInOrder = [
      headerFirstRef.current,
      headerSecondRef.current,
      logoRef.current,
      contentRef.current,
    ];

    const animationFromVars = { y: 100, opacity: 0 };
    const animationToVars = {
      opacity: 1,
      y: 0,
      duration: 1,
      stagger: 0.2,
      ease: "power2.out",
    };

    // #1: Animation of above the fold content on page load
    const fadeInTimeline = gsap.fromTo(
      elementsInOrder,
      animationFromVars,
      animationToVars,
    );

    // #2: Animation of below the fold content as the user scrolls
    if (featuredRef.current) {
      gsap.fromTo(featuredRef.current, animationFromVars, {
        scrollTrigger: {
          trigger: featuredRef.current,
          start: "top bottom",
          once: true,
          onEnter: () =>
            gsap.fromTo(
              featuredRef.current,
              animationFromVars,
              animationToVars,
            ),
        },
      });
    }

    // #3: Parallax scroll animation on the logo
    const parallaxTimeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: "top top",
          end: "bottom bottom",
          scrub: true,
        },
      })
      .fromTo(logoBgRef.current, { yPercent: 0 }, { yPercent: -15 });

    return () => {
      fadeInTimeline.kill();
      parallaxTimeline.kill();
    };
  }, [blok]);

  return (
    <section
      id={blok.sectionId}
      data-section-type="agreena"
      className={cn(
        blok.theme,
        "relative overflow-hidden bg-surface/200 text-content-text",
        "flex w-full place-content-center pt-[8em] pb-[12em] xl:pb-[calc(29em-12vw)]",
      )}
      ref={sectionRef}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          "z-10 m-auto flex flex-col place-items-center text-left",
          "w-full max-w-[75em] px-sm text-center",
        )}
      >
        <h1 className="t-strong-5xl lg:t-strong-6xl self-center whitespace-pre-wrap font-light text-content-heading">
          <span className="opacity-0 lg:block" ref={headerFirstRef}>
            {titleFirstPart}
          </span>
          <br className="lg:hidden" />
          <span className="opacity-0 lg:block" ref={headerSecondRef}>
            {titleSecondPart}
          </span>
        </h1>

        <div ref={contentRef} className="opacity-0">
          <div className="mx-auto mt-md mb-xs max-w-text text-content-text">
            <RichText
              data={blok.text}
              classNames={{
                paragraph: "t-prose-lg text-pretty",
              }}
              {...context}
            />
          </div>

          {blok.ctaLabel && blok.ctaLink && (
            <div className="mt-lg flex w-full max-w-[58em] place-content-center">
              <Button {...getLinkProps(blok.ctaLink, context)}>
                {blok.ctaLabel}
              </Button>
            </div>
          )}
        </div>

        <FloatingText
          blok={blok}
          logoRef={logoRef}
          logoBgRef={logoBgRef}
          {...context}
        />

        <Featured blok={blok} featuredRef={featuredRef} {...context} />
      </div>

      <SeedWave
        className="section-item-full absolute top-[0] h-full w-full bg-decor/accent"
        seedPatternColor={blok.seedPatternColor}
      />
    </section>
  );
}

type FeaturedProps = {
  featuredRef: RefObject<HTMLDivElement>;
} & AgreenaSectionProps;

function Featured({ blok, featuredRef }: FeaturedProps) {
  if (!blok.featuredLabel || !blok.featuredSection?.length) return null;

  return (
    <div
      className="mt-xl mb-md w-full max-w-[76em] px-sm text-content-link sm:mt-2xl md:px-4xl"
      ref={featuredRef}
    >
      <p className="t-prosi-md mb-md">{blok.featuredLabel}</p>

      <div className="flex w-full flex-wrap justify-center gap-lg md:gap-x-2xl">
        {blok.featuredSection?.map(featuredImage => {
          if (!featuredImage.filename) return null;

          return (
            <Image
              key={featuredImage.id}
              src={featuredImage.filename}
              {...getImageDimensions(featuredImage)}
              loader={imageLoader}
              alt={featuredImage.alt}
              className="h-[25px] w-auto sm:h-[33px]"
              data-chromatic="ignore"
            />
          );
        })}
      </div>
    </div>
  );
}

type FloatingTextProps = {
  logoBgRef: RefObject<HTMLImageElement>;
  logoRef: RefObject<HTMLDivElement>;
} & AgreenaSectionProps;

function FloatingText({ blok, logoBgRef, logoRef }: FloatingTextProps) {
  if (!blok.maskedLogo) return null;

  return (
    <div className="w-[105vw] max-w-screen-2xl transform max-sm:[&_*]:transform-none">
      <div
        className="relative mt-xl aspect-[4/1] w-full opacity-0 [mask-image:url(/masks/logo-agreena.svg)] [mask-size:100%] sm:mt-md"
        ref={logoRef}
      >
        {blok.maskedLogo.filename && (
          <Image
            loading="eager"
            ref={logoBgRef}
            width={1536}
            height={384}
            quality={50}
            src={blok.maskedLogo?.filename ?? ""}
            alt={blok.maskedLogo?.alt}
            sizes="105vw"
            loader={buildImageLoader({
              aspectRatio: 4 / 1,
              focus: blok.maskedLogo.focus,
            })}
            className="absolute h-[120%] w-full object-cover"
            data-chromatic="ignore"
          />
        )}
      </div>
    </div>
  );
}
