"use client";

import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";
import React from "react";
import { IconButton } from "~components/ui/icon-button";
import { type PageContext, buildImageLoader } from "~lib/storyblok";
import { cn } from "~utils";
import { RichText } from "../rich-text";
import type { QuoteItemBlok, QuotesSectionBlok } from "./types";
import { useControlQuotes } from "./use-control-quotes";

type QuotesSectionProps = {
  blok: QuotesSectionBlok;
} & PageContext;

export function QuoteSection({ blok, ...context }: QuotesSectionProps) {
  const {
    currentIndex,
    isSingleQuote,
    isNextDisabled,
    isPrevDisabled,
    handleNextClick,
    handlePrevClick,
  } = useControlQuotes(blok.quotes);

  return (
    <section
      id={blok.sectionId}
      data-section-type="qoute"
      className={cn(
        blok.theme,
        "pt-3xl-4xl",
        "grid place-items-center",
        blok.backgroundVariant === "accent"
          ? "bg-surface/contrast"
          : "bg-surface/100",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="grid max-w-[74em] grid-cols-1 items-center gap-lg lg:grid-cols-2 lg:gap-3xl">
        {blok.quotes.map((nestedBlok, index) => {
          const isVisible = index === currentIndex;

          return (
            <React.Fragment key={nestedBlok._uid}>
              <div
                className={cn(
                  "relative bg-surface/200 p-sm py-lg pb-xl sm:p-2xl-3xl",
                  blok.flipped
                    ? "col-[1/2] row-[2/3] lg:col-[2/3] lg:row-[1/2]"
                    : "col-[1/2] row-[2/3] lg:col-[1/2] lg:row-[1/2]",
                )}
              >
                <div
                  className={cn(
                    blok.backgroundVariant === "accent"
                      ? "bg-surface/contrast"
                      : "bg-surface/100",
                    "absolute top-[0] left-[0] h-full w-full",
                    "[mask-image:url(/masks/seed-corner-2.svg)] [mask-size:80%]",
                  )}
                />

                <QuoteItemImage
                  key={nestedBlok._uid}
                  blok={nestedBlok}
                  isVisible={isVisible}
                  {...context}
                />
              </div>

              <div
                className={cn(
                  "grid gap-lg max-lg:mx-sm lg:pb-2xl",
                  !isSingleQuote && "self-start",
                  blok.flipped
                    ? "col-start-1 row-start-1 lg:pl-lg xl:pl-4xl"
                    : "col-start-1 row-start-1 lg:col-start-2 lg:pr-lg xl:pr-4xl",
                  isVisible ? "z-[1] opacity-100" : "z-0 opacity-0",
                )}
              >
                {!isSingleQuote && isVisible && (
                  <div className="flex gap-sm">
                    <IconButton
                      variant="secondary"
                      name="ArrowLeft"
                      disabled={isPrevDisabled}
                      onClick={handlePrevClick}
                    />

                    <IconButton
                      variant="secondary"
                      name="ArrowRight"
                      disabled={isNextDisabled}
                      onClick={handleNextClick}
                    />
                  </div>
                )}

                <QuoteItemContent
                  key={nestedBlok._uid}
                  blok={nestedBlok}
                  isVisible={isVisible}
                  {...context}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
}

type QuoteItemProps = {
  blok: QuoteItemBlok;
  isVisible: boolean;
} & PageContext;

function QuoteItemContent({ blok, isVisible, ...context }: QuoteItemProps) {
  return (
    <section
      className={cn(
        "grid place-content-start gap-lg transition-opacity duration-long ease-in-out",
        isVisible ? "opacity-100" : "opacity-0",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="flex max-w-text flex-col gap-2xs text-content-heading">
        {blok.leading && <h3 className="t-prosi-lg">{blok.leading}</h3>}

        <RichText
          data={blok.text}
          classNames={{
            paragraph: "t-prosi-2xl hyphens-none break-keep",
          }}
          {...context}
        />
      </div>

      <div className="t-default-sm flex flex-col gap-3xs">
        <span className="t-strong-sm text-content-text leading-tight">
          {blok.author}
        </span>
        {blok.info && (
          <RichText
            data={blok.info}
            classNames={{
              paragraph: "t-default-sm text-content-text",
            }}
            {...context}
          />
        )}
      </div>
    </section>
  );
}

function QuoteItemImage({ blok, isVisible }: QuoteItemProps) {
  return (
    <>
      <Image
        sizes="100vw"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 1,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        className={cn(
          "relative z-10 w-full overflow-hidden rounded-sm transition-opacity duration-long sm:hidden",
          isVisible ? "opacity-100" : "opacity-0",
        )}
        title={blok.image.title ?? undefined}
        width={400}
        height={400}
        data-chromatic="ignore"
        {...storyblokEditable(blok)}
      />

      <Image
        sizes="33vw"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 4 / 5,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        className={cn(
          "relative z-10 w-full overflow-hidden rounded-sm transition-opacity duration-long max-sm:hidden",
          isVisible ? "opacity-100" : "opacity-0",
        )}
        title={blok.image.title ?? undefined}
        width={800}
        height={1000}
        data-chromatic="ignore"
        {...storyblokEditable(blok)}
      />
    </>
  );
}
