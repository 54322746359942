import type { ISbRichtext } from "@storyblok/react";
import {
  MARK_ANCHOR,
  MARK_BOLD,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_LINK,
  MARK_STRIKE,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
  NODE_HEADING,
  NODE_HR,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  type RenderOptions,
  render,
} from "storyblok-rich-text-react-renderer";
import { type PageContext, getLinkMarkProps } from "~lib/storyblok";
import { cn } from "~utils";

type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

type ClassNameType =
  | keyof NonNullable<RenderOptions["markResolvers"]>
  | keyof NonNullable<RenderOptions["nodeResolvers"]>
  | Headings;

type RichTextProps = {
  data: ISbRichtext;
  // Use this to add classnames to all elements.
  // Useful for a) only a single root element, b) if you have multiple possible root elements c) you want a class applied to all elements, including sub-elements
  className?: string;
  // A lookup of classnames to apply to each type of element
  classNames?: Partial<Record<ClassNameType, string>>;
} & Pick<RenderOptions, "blokResolvers" | "markResolvers"> &
  PageContext;

export function RichText({
  data,
  className,
  classNames = {},
  blokResolvers,
  markResolvers,
  ...context
}: RichTextProps) {
  if (isTextEmpty(data)) return <></>;

  return render(data, {
    blokResolvers,
    markResolvers: {
      [MARK_ANCHOR]: (children, props) => (
        <a
          href={`#${props.id}`}
          className={
            classNames[MARK_ANCHOR] || cn("text-content-link underline")
          }
        >
          {children}
        </a>
      ),
      [MARK_BOLD]: children => (
        <strong className={cn(classNames[MARK_BOLD] || "font-bold")}>
          {children}
        </strong>
      ),
      [MARK_HIGHLIGHT]: children => (
        <mark className={cn(classNames[MARK_HIGHLIGHT])}>{children}</mark>
      ),
      [MARK_ITALIC]: children => (
        <em className={cn(classNames[MARK_ITALIC] || "italic")}>{children}</em>
      ),
      [MARK_LINK]: (children, props) => (
        <a
          {...getLinkMarkProps(props, context)}
          className={cn(classNames[MARK_LINK] || "text-content-link underline")}
        >
          {children}
        </a>
      ),
      [MARK_STRIKE]: children => (
        <s className={cn(classNames[MARK_STRIKE])}>{children}</s>
      ),
      [MARK_SUBSCRIPT]: children => (
        <sub className={cn(classNames[MARK_SUBSCRIPT])}>{children}</sub>
      ),
      [MARK_SUPERSCRIPT]: children => (
        <sup className={cn(classNames[MARK_SUPERSCRIPT])}>{children}</sup>
      ),
      [MARK_UNDERLINE]: children => (
        <span className={cn(classNames[MARK_UNDERLINE] || "underline")}>
          {children}
        </span>
      ),
      ...markResolvers,
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, { level }) => {
        switch (level) {
          case 1:
            return (
              <h1 className={cn(classNames[NODE_HEADING], classNames.h1)}>
                {children}
              </h1>
            );
          case 3:
            return (
              <h3 className={cn(classNames[NODE_HEADING], classNames.h3)}>
                {children}
              </h3>
            );
          case 4:
            return (
              <h4 className={cn(classNames[NODE_HEADING], classNames.h4)}>
                {children}
              </h4>
            );
          case 5:
            return (
              <h5 className={cn(classNames[NODE_HEADING], classNames.h5)}>
                {children}
              </h5>
            );
          case 6:
            return (
              <h6 className={cn(classNames[NODE_HEADING], classNames.h6)}>
                {children}
              </h6>
            );
          default:
            return (
              <h2 className={cn(classNames[NODE_HEADING], classNames.h2)}>
                {children}
              </h2>
            );
        }
      },
      [NODE_HR]: () => <hr className={cn(classNames[NODE_HR])} />,
      [NODE_LI]: children => (
        <li className={cn(classNames[NODE_LI] || "list-item [&_p]:mb-[0px]")}>
          {children}
        </li>
      ),
      [NODE_OL]: children => (
        <ol
          className={cn(
            classNames[NODE_OL] ||
              "my-[1.875em] grid list-disc gap-[0.625em] pl-[1.25em]",
          )}
        >
          {children}
        </ol>
      ),
      [NODE_PARAGRAPH]: children => (
        <p className={cn(className, classNames[NODE_PARAGRAPH])}>{children}</p>
      ),
      [NODE_QUOTE]: children => (
        <blockquote className={cn(classNames[NODE_QUOTE])}>
          {children}
        </blockquote>
      ),
      [NODE_UL]: children => (
        <ul
          className={cn(
            classNames[NODE_UL] ||
              "my-[1.875em] grid list-disc gap-[0.625em] pl-[1.25em]",
          )}
        >
          {children}
        </ul>
      ),
    },
  });
}

function isTextEmpty(data: ISbRichtext) {
  if (!data || !data.content) return true;

  return data.content.every(block => {
    return !block.content || block.content.length === 0;
  });
}
