import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { cn } from "~utils";

import { useState } from "react";
import { Icon } from "~components/ui";
import { getFilenameDimensions, imageLoader } from "~lib/storyblok";
import type { MigrationVideoSectionBlok } from "./types";

type MigrationVideoSectionProps = {
  blok: MigrationVideoSectionBlok;
};

export function MigrationVideoSection({ blok }: MigrationVideoSectionProps) {
  const [isPosterClicked, setIsPosterClicked] = useState(false);

  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-video"
      className={cn(
        blok.theme,
        "w-full bg-surface/contrast py-2xl text-content-heading",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="mx-auto flex max-w-screen-2xl flex-col items-center gap-lg px-sm md:px-lg-xl">
        <h3 className="t-prose-lg max-w-leading text-center">{blok.leading}</h3>

        <h1 className="-mt-md t-strong-3xl max-w-title text-balance text-center">
          {blok.title}
        </h1>

        <div className="t-strong-xl flex max-w-text flex-col gap-sm text-pretty text-center">
          {isPosterClicked ? (
            // biome-ignore lint/a11y/useMediaCaption: skipped becuase ofmigration
            <video
              className="aspect-[1.776] w-full rounded-sm"
              controls
              autoPlay
              preload="none"
            >
              <source src={blok.video.filename ?? undefined} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            // biome-ignore lint/a11y/useKeyWithClickEvents: skipped becuase ofmigration
            <div
              className="grid w-full cursor-pointer grid-cols-1"
              onClick={() => setIsPosterClicked(true)}
            >
              <Image
                className="col-start-1 row-start-1 overflow-hidden rounded-sm"
                {...getFilenameDimensions(blok.videoPoster.filename)}
                sizes="100vw"
                src={blok.videoPoster.filename ?? ""}
                loader={imageLoader}
                alt={blok.videoPoster.alt ?? ""}
                title={blok.videoPoster.title ?? ""}
                data-chromatic="ignore"
              />
              <div className="col-start-1 row-start-1 flex items-center justify-center">
                <Icon
                  name="PlayCircle"
                  className="text-[4em] text-decor/white"
                  weight="regular"
                />
              </div>
            </div>
          )}

          <p className="t-prose-md px-xs text-content-text">{blok.text}</p>
        </div>
      </div>
    </section>
  );
}
