import { renderRichText, storyblokEditable } from "@storyblok/react/rsc";

import Image from "next/image";
import { Button } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getFilenameDimensions,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";
import type { MigrationSideBySideSectionBlok } from "./types";

type MigrationSideBySideSectionBlokProps = {
  blok: MigrationSideBySideSectionBlok;
} & PageContext;

export function MigrationSideBySideSection({
  blok,
  ...context
}: MigrationSideBySideSectionBlokProps) {
  const richText = renderRichText(blok.text);
  const richImageCaption = renderRichText(blok.imageCaption);

  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-side-by-side"
      className={cn(
        blok.theme,
        "flex w-full justify-center bg-decor/white text-content-text sm:py-xl",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="relative w-full max-w-screen-xl px-sm md:px-lg-xl lg:pr-[0]">
        <div
          className={cn(
            "absolute right-[0] h-full w-full rounded-sm bg-surface/100 lg:w-[70%]",
            blok.variant === "flipped" && "left-[0]",
          )}
        />

        <div
          className={cn(
            "relative z-1 my-lg flex flex-col items-center gap-md sm:my-2xl lg:flex-row",
            blok.variant === "flipped" && "lg:flex-row-reverse",
          )}
        >
          <div className="height-full width-full flex-1">
            <Image
              {...getFilenameDimensions(blok.image.filename)}
              className="h-auto overflow-hidden rounded-sm lg:w-[41em] lg:pr-[0]"
              sizes="(max-width: 1024px) 100vw, 50vw"
              src={blok.image.filename ?? ""}
              alt={blok.image.alt ?? ""}
              title={blok.image.title ?? ""}
              loader={buildImageLoader({
                aspectRatio: 1 / 1,
                focus: blok.image.focus,
              })}
              data-chromatic="ignore"
            />
            {blok.imageCaption && (
              <div
                className="t-prose-md mt-sm text-center text-content-text lg:text-left"
                // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
                dangerouslySetInnerHTML={{ __html: richImageCaption }}
              />
            )}
          </div>

          <div className="grid flex-1 lg:px-2xl">
            {blok.header && (
              <h1 className="t-strong-xl mb-md max-w-title text-balance text-content-heading">
                {blok.header}
              </h1>
            )}

            <div
              className={cn(
                "t-prose-md grid max-w-text gap-sm text-pretty text-content-text",
                `sm:[&_blockquote]:t-strong-xl [&_blockquote_*]:inline [&_blockquote]:after:content-['”'] [&_blockquote]:before:content-['“']`,
                "[&_li]:mt-xs [&_ul]:list-disc",
                // Needed to overwrite the className styling from global
                "font-serifRegular",
              )}
              // biome-ignore lint/security/noDangerouslySetInnerHtml: CMS is safe
              dangerouslySetInnerHTML={{ __html: richText }}
            />

            {(blok.ctaHeadline || blok.ctaText) && (
              <div className="grid place-items-start gap-sm pt-md">
                {blok.ctaHeadline && (
                  <h2 className="t-strong-lg text-content-heading">
                    {blok.ctaHeadline}
                  </h2>
                )}

                {blok.ctaText && blok.ctaLink && (
                  <Button {...getLinkProps(blok.ctaLink, context)} arrow>
                    {blok.ctaText}
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
