import { type SbBlokData, storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { Button, Icon } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getLinkProps,
} from "~lib/storyblok";
import { cn } from "~utils";

import { RichText } from "../rich-text";
import type { FeaturesSectionBlok } from "./types";

type SplitSectionProps = {
  blok: FeaturesSectionBlok;
} & PageContext;

export function FeaturesSection({ blok, ...context }: SplitSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="features"
      className={cn(
        blok.theme,
        "section bg-surface/200",
        blok.backgroundVariant === "darker" && "bg-surface/300",
        blok.backgroundVariant === "lighter" && "bg-surface/100",
        blok.backgroundVariant === "white" && "bg-others-white",
      )}
      {...storyblokEditable(blok)}
    >
      <div className="grid gap-2xl">
        <div className="grid gap-lg">
          <div className="flex flex-col gap-sm">
            {blok.leading && (
              <span className="t-prosi-xl -mb-2xs max-w-sub text-content-heading">
                {blok.leading}
              </span>
            )}
            <h2 className="t-strong-3xl max-w-title text-balance text-content-heading">
              {blok.title}
            </h2>
            <RichText
              data={blok.text}
              className="t-strong-lg max-w-sub text-pretty"
              {...context}
            />
          </div>

          {blok.items.length && (
            <ul className="grid xl:grid-cols-3">
              {blok.items.map(item => (
                <li
                  key={item._uid}
                  className="flex gap-sm p-sm max-xl:items-center"
                  {...storyblokEditable(item as SbBlokData)}
                >
                  <Icon
                    name={item.icon}
                    weight="duotone"
                    className="circle-icon"
                  />
                  <RichText
                    data={item.text}
                    {...context}
                    className="max-w-sub text-pretty"
                  />
                </li>
              ))}
            </ul>
          )}
        </div>

        <>
          {blok.linkItems.map(item => (
            <div
              key={item._uid}
              className="flex place-items-center gap-lg max-xl:flex-col md:gap-xl-2xl"
              {...storyblokEditable(item)}
            >
              {item.image.filename && (
                <Image
                  sizes="(min-width: 1280px) 440px, 100vw"
                  src={item.image.filename}
                  width={440}
                  height={440}
                  loader={buildImageLoader({
                    aspectRatio: 1,
                    focus: item.image.focus,
                  })}
                  alt={item.image.alt}
                  data-chromatic="ignore"
                  className="w-full"
                />
              )}

              <div className="flex flex-col place-items-start gap-sm">
                <h4 className="t-strong-2xl max-w-title text-balance text-content-heading">
                  {item.title}
                </h4>

                <RichText
                  data={item.text}
                  className="t-strong-lg max-w-text text-pretty"
                  {...context}
                />
                {item.ctaLink && item.ctaText && (
                  <Button editorial {...getLinkProps(item.ctaLink, context)}>
                    {item.ctaText}
                  </Button>
                )}
              </div>
            </div>
          ))}
        </>
      </div>
    </section>
  );
}
