import { storyblokEditable } from "@storyblok/react/rsc";

import { cn } from "~utils";

import Image from "next/image";
import { getFilenameDimensions, imageLoader } from "~lib/storyblok";
import type { MigrationInvestorsSectionBlok } from "./types";

type MigrationInvestorsSectionProps = {
  blok: MigrationInvestorsSectionBlok;
};

export function MigrationInvestorsSection({
  blok,
}: MigrationInvestorsSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-investors"
      className={cn(blok.theme, "bg-decor/white px-md py-2xl")}
      {...storyblokEditable(blok)}
    >
      <div className="flex flex-col items-center gap-2xl md:gap-xl">
        <h1 className="t-strong-3xl text-content-heading">{blok.title}</h1>

        <div className="flex flex-row flex-wrap justify-center gap-lg">
          {blok.images.map(image => (
            <Image
              key={image.id}
              {...getFilenameDimensions(image.filename)}
              sizes="200px"
              className="max-h-[5em] w-auto self-center"
              src={image.filename ?? ""}
              loader={imageLoader}
              alt={image.alt ?? ""}
              title={image.title ?? ""}
              data-chromatic="ignore"
            />
          ))}
        </div>

        <p className="md:t-strong-xl text-center text-content-heading">
          {blok.text}
        </p>
      </div>
    </section>
  );
}
