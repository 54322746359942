import { storyblokEditable } from "@storyblok/react/rsc";
import Image from "next/image";

import { cn } from "~utils";

import { Button } from "~components/ui";
import {
  type PageContext,
  buildImageLoader,
  getFilenameDimensions,
  getLinkProps,
} from "~lib/storyblok";
import type { MigrationHelpSectionBlok } from "./types";

type MigrationHelpSectionProps = {
  blok: MigrationHelpSectionBlok;
} & PageContext;

export function MigrationHelpSection({
  blok,
  ...context
}: MigrationHelpSectionProps) {
  return (
    <section
      id={blok.sectionId}
      data-section-type="migration-help"
      className={cn(
        blok.theme,
        "relative grid w-full place-items-start overflow-hidden",
      )}
      {...storyblokEditable(blok)}
    >
      <Image
        className="-z-10 absolute h-[min(27em,100%)] w-full object-cover object-left-top sm:hidden"
        {...getFilenameDimensions(blok.image.filename)}
        sizes="100vw"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({ aspectRatio: 3 / 4 })}
        alt={blok.image.alt ?? ""}
        title={blok.image.title ?? ""}
        data-chromatic="ignore"
      />

      <Image
        className="-z-10 absolute hidden h-[min(27em,100%)] w-full object-cover object-left-top sm:block md:hidden"
        {...getFilenameDimensions(blok.image.filename)}
        sizes="100vw"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 16 / 9,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        title={blok.image.title ?? ""}
        data-chromatic="ignore"
      />

      <Image
        className="-z-10 absolute hidden h-[min(27em,100%)] w-full object-cover object-left-top md:block"
        {...getFilenameDimensions(blok.image.filename)}
        sizes="100vw"
        src={blok.image.filename ?? ""}
        loader={buildImageLoader({
          aspectRatio: 5 / 2,
          focus: blok.image.focus,
        })}
        alt={blok.image.alt ?? ""}
        title={blok.image.title ?? ""}
        data-chromatic="ignore"
      />

      <div className="col-start-1 row-start-1 mx-auto flex w-full max-w-screen-xl flex-col gap-lg px-sm py-3xl md:px-lg-xl">
        <p className="t-strong-md max-w-leading">{blok.title}</p>

        <h1 className="-mt-sm t-strong-2xl max-w-title text-pretty text-decor/white">
          {blok.text}
        </h1>

        <Button
          className="self-start"
          arrow
          {...getLinkProps(blok.ctaLink, context)}
        >
          {blok.ctaText}
        </Button>
      </div>
    </section>
  );
}
