import type { ISbRichtext, ISbStoryData } from "@storyblok/react/rsc";

import type { ArticleType, SectionThemes } from "~components/types";
import type {
  ResolvedBlok,
  StoryblokAsset,
  StoryblokLink,
} from "~lib/storyblok";

import type { AuthorBlok } from "../author/types";

export type ArticlePageBlok = {
  component: "ArticlePage";
  author?: ResolvedBlok<AuthorBlok>;
  date?: string;
  externalLink?: StoryblokLink;
  image: StoryblokAsset;
  isDisabled?: boolean;
  text: ISbRichtext;
  theme: SectionThemes;
  title: string;
  type: ArticleType;

  // SEO
  noindex: boolean;
  metaTitle: string;
  metaDescription: string;
  ogImage?: StoryblokAsset;
  ogTitle?: string;
  ogDescription?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: StoryblokAsset;
};

export function isArticlePage(
  item: unknown,
): item is ISbStoryData<ArticlePageBlok> {
  if (!item || typeof item !== "object") return false;
  if (!("content" in item) || !item.content || typeof item.content !== "object")
    return false;
  if (!("component" in item.content)) return false;

  return item.content.component === "ArticlePage";
}
